

<template>

    <div class="py-5" style="">

        <h1 class="text-4xl oeno_portfolio_title pb-10">Case Price Guide</h1>

        <table width="100%" style="border: 1px !important;">
            <tr>
                <td v-for="(price, index) in priceData" v-bind:key="index">
                    <div class="w-20 h-20 bg-black mx-auto rounded-full text-l text-white flex">
                        <span class="text-white text-center w-full pt-5">
                            <i class="fa fa-check w-full fill-current white">GBP<br/>{{ currencyFormatter(price.price) }}</i></span>
                    </div>
                    <div class="w-full bg-grey-light rounded items-center align-left align-center flex-1">
                        <div class="bg-green-light text-xs leading-none py-1 text-center text-grey-darkest rounded "
                            style="width: 20%"></div>
                    </div>

                </td>
            </tr>
            <tr>
                <td v-for="(price, index) in priceData" v-bind:key="index">
                    <center class="text-4xl oeno_portfolio_title_small">
                        {{ price.name }}
                    </center>
                    
                </td>
            </tr>
        </table>
        
    </div>


</template>
   
<script>


export default {
    name: 'PriceRange',
    data() {
        return {
            priceData: [],
        }
    },
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    mounted() {

        if (parseFloat(this.data.livePrices.data["price-average"]) > 0) { this.priceData.push({ name: "OENO Market Estimate", price: parseFloat(this.data.livePrices.data["price-average"] * this.data.wines.units_per_case) }) }
        //if (parseFloat(this.data.livePrices.data["winesearcher-price"]) > 0) { this.priceData.push({ name: "Wine Searcher", price: parseFloat(this.data.livePrices.data["winesearcher-price"] * this.data.wines.units_per_case) }) }
        //if (parseFloat(this.data.livePrices.data["secondaryAvailStock-price"]) > 0) { this.priceData.push({ name: "OENO Last Sold", price: parseFloat(this.data.livePrices.data["secondaryAvailStock-price"] * this.data.wines.units_per_case) }) }
        //if (parseFloat(this.data.livePrices.data["livex-price"]) > 0) { this.priceData.push({ name: "Livex", price: parseFloat(this.data.livePrices.data["livex-price"] * this.data.wines.units_per_case) }) }

        this.priceData.sort((a, b) => a.price - b.price);
    },
    methods: {
    },
    computed: {

    }

}
</script>

