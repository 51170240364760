<template>
  <div>
    <div
      style="border-bottom: 0.5px solid #000000"
      class="grid grid-cols-4 pb-2"
    >
      <p class="oeno_col_heading ml-10">Name</p>

      <p class="float-right oeno_col_heading">Quantity</p>

      <p class="float-right oeno_col_heading">Purchase Price</p>

      <p class="oeno_col_heading">Market Estimated Value</p>
    </div>

    <div
      v-if="wine.wines"
      class="grid grid-cols-4 gap-4 mt-5 pb-5"
      style="border-bottom: 0.5px solid #000000"
    >
      <div class="ml-10">
        <span class="oeno_wine_name">{{ wine.wines.name }}</span
        ><br />
        <span class="oeno_portfolio_winequantity_case_bottle"
          ><i>{{ winary(wine) }}</i></span
        ><br />
      </div>
      <div class="">
        <span class="oeno_wine_name">{{ showTotalBottle(wine) }}</span
        ><br />
        <span class="oeno_portfolio_winequantity_case_bottle"
          ><i>{{ showquantity(wine) }}</i></span
        >
      </div>
      <div class="">
        <span class="oeno_wine_name"
          >{{ currencyFormatter(getPurchasePrice(wine)) }}
          {{ order.currency }} </span
        ><br />
      </div>
      <div class="">
        <price-range-simple :data="wine" />
        <br /><span
          :class="[
            priceUp(order, wine)
              ? ' oeno_portfolio_price_up'
              : ' oeno_portfolio_price_down',
          ]"
          >{{ getChange(order, wine) }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import PriceRangeSimple from "@/components/wine/PriceRangeSimple.vue";

export default {
  name: "BuyWineSummary",
  props: {
    wine: {
      type: Object,
      default() {
        return {};
      },
    },
    order: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    PriceRangeSimple,
  },
  methods: {
    winary(wine) {
      var winary = "n/a";
      var vintage = "";
      var size = "";

      if (wine.wines) {
        if (wine.wines.winery) {
          vintage = wine.wines.vintage;
          if (wine.wines.winery.name) {
            winary = wine.wines.winery.name;
          }
          if (wine.wines.size.name) {
            size = wine.wines.size.name;
          }
        }
      }

      winary = winary + " " + vintage;

      if (size != "") {
        winary = winary + " " + size + "ML";
      }

      return winary;
    },

    showTotalBottle(wine) {
      var quantity = 0;
      var unitsPerCase = 0;
      var retStr = "";

      if (wine.quantity) {
        quantity = parseInt(wine.quantity);
      }
      if (wine.wines) {
        if (wine.wines.units_per_case) {
          unitsPerCase = parseInt(wine.wines.units_per_case);
        }
      }

      if (quantity * unitsPerCase > 1) {
        retStr = quantity * unitsPerCase + " Bottles";
      } else {
        retStr = quantity * unitsPerCase + " Bottle";
      }

      return retStr;
    },
    showquantity(wine) {
      var quantity = 0;
      var unitsPerCase = 0;
      var retStr = "";

      if (wine.quantity) {
        quantity = parseInt(wine.quantity);
      }
      if (wine.wines) {
        if (wine.wines.units_per_case) {
          unitsPerCase = parseInt(wine.wines.units_per_case);
        }
      }

      if (quantity > 0) {
        if (quantity > 1) {
          retStr = quantity + " Cases";
        } else {
          retStr = quantity + " Case";
        }
      }

      if (unitsPerCase > 0) {
        if (unitsPerCase > 1) {
          retStr = retStr + " (" + unitsPerCase + " bottles per case)";
        } else {
          retStr = retStr + " (" + unitsPerCase + " bottle per case)";
        }
      }

      return retStr;
    },

    getPurchasePrice(item) {
      return item.bottle_price * item.quantity * item.wines.units_per_case;
    },    
    getValuesForItem(investment, wine) {
      var isInLastSixMonths = "N";
      var orderDate = moment(investment.order_date);
      var sixMonthsAgo = moment().subtract(6, "months");
      if (sixMonthsAgo < orderDate) {
        isInLastSixMonths = "Y";
      }

      var total =
        parseInt(
          parseFloat(
            wine.quantity *
              wine.wines.units_per_case *
              wine.livePrices.data["price-average"]
          ) * 100
        ) / 100;

      var buytotal =
        wine.quantity * wine.wines.units_per_case * wine.bottle_price;

      if (isInLastSixMonths == "Y") {
        if (total < buytotal) {
          total = buytotal;
        }
      }

      var changePerc =
        ((parseFloat(total) - parseFloat(buytotal)) / parseFloat(buytotal)) *
        100;

      var changePrice = parseFloat(total) - parseFloat(buytotal);

      return {
        changePerc: changePerc,
        changePrice: changePrice,
        total: total,
        currency: investment.currency,
      };
    },


    getPrice(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);
      this.getTotalPrice = priceValues.total;
      return this.getTotalPrice;
    },
    priceUp(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      if (priceValues.changePrice >= 0) {
        return true;
      } else {
        return false;
      }
    },
    


    getChange(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return (
        this.currencyFormatter(priceValues.changePrice) +
        "(" +
        this.currencyFormatter(priceValues.changePerc) +
        "%)"
      );
    },    
  },
};
</script>
