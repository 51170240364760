<template>
  <div class="">
    <span v-for="(price, index) in priceData" v-bind:key="index" class="oeno_wine_name">
      {{ currencyFormatter(price.price) }} GBP
      </span
    >
  </div>
</template>

<script>
export default {
  name: "PriceRangeSimple",
  data() {
    return {
      priceData: [],
    };
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    if (parseFloat(this.data.livePrices.data["price-average"]) > 0) {
      this.priceData.push({
        name: "OENO Market Estimate",
        price: parseFloat(
          this.data.livePrices.data["price-average"] *
            this.data.wines.units_per_case
        ),
      });
    }
    this.priceData.sort((a, b) => a.price - b.price);
  },
  methods: {},
  computed: {},
};
</script>
