<template>
  <div>
    <NavBar class="fixed top-0 left-0 right-0"></NavBar>

    <secondary-navbar
      title="Sell Wine"
      :showbroker="false"
      :showaction="false"
      :showback="true"
    />

    <div class="w-full mt-6 grid grid-cols-10 hidden sm:grid" id="page-login">
      <div class="col-span-2 sm:block 0 background-f4">
        <left-side-strip
          :showBrokerButton="false"
          :showPie="false"
          :showWine="true"
          :wine="wine"
        />
      </div>

      <div class="col-span-8">

        <wine-detail-table :wine="wine" :order="order" />

        <div v-if="$store.state.portfolio" class="m-20">
          <span id="download_loader" v-if="!showdata"></span>
          <div v-if="showdata">
            <div class="grid grid-cols-2 gap-4 mt-5 pb-5">
              <div>
                <price-range :data="wine" />
              </div>

              <div>
                <div class="grid grid-cols-5 gap-4">
                  <div class="col-span-3 mt-6">
                    <span class="oeno_portfolio_winetitle"
                      >Please enter the amount you <br />would like to sell for
                      ( GBP )</span
                    >
                  </div>
                  <div class="col-span-2">
                    <md-field>
                      <md-input
                        v-model="amount"
                        @keyup="validateAmount()"
                        @change="validateAmount()"
                        type="number"
                      ></md-input>
                    </md-field>
                    <span v-if="!validAmount" class="errortext"
                      >The amount to sell for needs to greater than GBP 1</span
                    >
                  </div>

                  <div class="col-span-3 mt-6">
                    <span class="oeno_portfolio_winetitle"
                      >Please enter the number of cases you would like to
                      sell</span
                    >
                  </div>
                  <div class="col-span-2">
                    <md-field>
                      <md-input
                        v-model="cases"
                        @keyup="validateCases(maxcases)"
                        @change="validateCases(maxcases)"
                        type="number"
                      ></md-input>
                    </md-field>
                    <span v-if="!validCases" class="errortext"
                      >The amount of case to be added needs to greater than
                      1</span
                    >
                  </div>

                  <div class="col-span-5">
                    <button
                      @click="modalActive = true"
                      class="float-right oeno-button-sm"
                      :disabled="!(validCases && validAmount) || processing"
                      >{{ processing ? "Processing" : "Continue" }}</button
                    >
                  </div>

                  <md-dialog-confirm
                    :md-active.sync="modalActive"
                    md-title="Terms and Conditions"
                    md-content="<p>By agreeing to OenoFuture Limited’s (‘the Company’) broking terms, you are authorising and granting the Company a 90 day exclusivity period to offer the wine for general sale (‘the Exclusivity Period’). The parties will agree on a purchase price for the wine that the Company will purchase from you (‘the Purchase Price’). If the Company sells the wine to a third party (‘the Purchaser’) within the Exclusivity Period, then the Company’s purchase from you will be deemed to have occurred immediately before the sale to the Purchaser, and the Purchase Price will be paid by the Company to you within 20 working days of receipt of the proceeds by the Company from its sale to the Purchaser.</p>
            <p>The purchase and resale of your wines is conditional upon their safe receipt (at your expense) onto the London City Bond premises, and upon the condition of the wines being, in the opinion of the Company, as agreed and suitable for resale. If you are offering wines for broking from outside the Company’s storage facility then the wines must be in the original wooden case (OWC), and in good condition. The brokerage service only applies to wine by the case and not individual bottles. It does not apply to a mixed case unless specifically agreed otherwise. We may introduce additional terms and conditions in relation to the brokerage service which will be advised to you at the relevant time.</p>         
            <p>If the Purchaser cancels the sale contract with the Company (where they are permitted by law to do so), the Company has the option to cancel the contract for the purchase of the wine from you, by giving written notice (including by email). If payment is withheld by the Purchaser and the wine is to be returned (either by the Purchaser or at your request prior to the sale, or if the wines cannot be sold, or if the contract is cancelled) the Company has the option to cancel the contract for the purchase of the wine from you, by giving written notice (including by email).</p>"
                    md-confirm-text="Agree"
                    md-cancel-text="Disagree"
                    @md-cancel="modalActive = false"
                    @md-confirm="processSell()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { HTTP } from "@/axios.js";


import NavBar from "@/components/layout/navbar.vue";
import SecondaryNavbar from "@/components/layout/SecondaryNavbar.vue";
import Footer from "@/components/layout/footer.vue";
import LeftSideStrip from "@/components/pageparts/LeftSideStrip.vue";
import PriceRange from "@/components/wine/PriceRange.vue";
import WineDetailTable from "@/components/sell/WineDetailTable.vue";

export default {
  data() {
    return {
      stock: [],
      wine: {},
      order: {},
      showdata: false,
      amount: 1,
      validAmount: true,
      cases: 1,
      maxcases: 1,
      validCases: true,
      processing: false,
      modalActive: false,
    };
  },
  components: {
    NavBar,
    Footer,
    LeftSideStrip,
    SecondaryNavbar,
    PriceRange,
    WineDetailTable
  },

  mounted() {
    this.showdata = false;

    this.$store.state.portfolio.forEach((invItem) => {
      invItem.items.forEach((wineItem) => {
        if (
          wineItem.wine_id == this.$route.params.id &&
          invItem.id == this.$route.params.orderId
        ) {
          this.wine = wineItem;
          this.order = invItem;

          if (parseFloat(wineItem.livePrices.data["price-average"]) > 0) {
            this.amount = (
              parseInt(
                parseFloat(wineItem.livePrices.data["price-average"]) *
                  wineItem.wines.units_per_case *
                  100
              ) / 100
            ).toFixed(2);
          } else {
            this.amount = (
              parseInt(
                wineItem.bottle_price * wineItem.wines.units_per_case * 100
              ) / 100
            ).toFixed(2);
          }

          this.cases = parseInt(wineItem.quantity);
          this.maxcases = parseInt(wineItem.quantity);

          this.showdata = true;
        }
      });
    });
  },
  computed: {
    getUserName() {
      var userName = "";
      if (this.$store.state.user) {
        userName =
          this.$store.state.user.firstname +
          " " +
          this.$store.state.user.surname;
      }
      return userName;
    },
    getUserReference() {
      var reference = "";
      if (this.$store.state.user) {
        reference = "(" + this.$store.state.user.unique_number + ")";
      }
      return reference;
    },
  },
  methods: {
    processSell() {
      this.modalActive = false;
      this.processing = true;

      // Call the backend service and get the volt redirect URL
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.token,
      };

      HTTP.post(
        "/api/sell-wine",
        {
          wineid: this.$route.params.id,
          orderid: this.$route.params.orderId,
          amount: this.amount,
          quantity: this.cases,
          type: "case",
        },
        { headers: headers }
      )
        .then((response) => {
          if (response.data.statuscode == 200) {
            this.updateProfile();

            this.processing = false;

            this.$router.push("/portfolio");
          }
        })
        .catch((error) => {
          this.processing = false;

          this.$notify({
            group: "oeno",
            title: "Sell Wine Error",
            text: "Sorry there seems to have been an error processing your request, please try again later.",
            type: "error",
          });

          console.log(error);
        });
    },
    validateAmount() {
      if (parseFloat(this.amount) < 1) {
        this.validAmount = false;
      } else {
        this.validAmount = true;
      }
    },
    validateCases(max) {
      if (parseFloat(this.cases) < 1) {
        this.validCases = false;
      } else if (parseFloat(this.cases) > max) {
        this.validCases = true;
        this.cases = this.maxcases;
      } else {
        this.validCases = true;
      }
    },




  },
};
</script>
